import { renderDatatable } from "../application"

columns = [
  { data: "first_name" },
  { data: "last_name" },
  { data: "email" },
  { data: "registered" },
  { data: "country_of_practice" },
  { data: "city" },
  {
    data: "locked",
    render: function(data, _) {
      if (data === 'Yes') {
        return `<span class="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset bg-red-50 text-red-700 ring-red-600/10">${data}</span>`
      } else {
        return data
      }
    }
  },
  { data: "action", searchable: false, orderable: false }
]

order = [[3, "desc"]]

renderDatatable($('#admin-users-datatable'), columns, order)
