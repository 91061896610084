import { renderDatatable } from "../application"

columns = [
  { data: "email" },
  { data: "profession" },
  { data: "speciality" },
  { data: "preferred_location" },
  { data: "status" },
  { data: "created_at" }
]

order = [[5, "desc"]]

renderDatatable($('#admin-candidates-datatable'), columns, order)
